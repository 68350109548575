import React, { useState } from "react"
// import { graphql } from "gatsby"
// import parse from "html-react-parser"
import Marquee from "react-fast-marquee"
import { StaticImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"

//import components
import Navbar from "../components/Navbar/Navbar"
// import ContactFormOpenButton from "../components/Button/ContactFormOpenButton"
import CollapseBox from "../components/CollapseBox/CollapseBox"
import Footer from "../components/Footer/Footer"
// import OrderForm from "../components/OrderForm/OrderForm"

//import images
import Arrow from "../images/arrow.svg"
import Dermo from "../images/icons/dermo.svg"
import TesteG from "../images/icons/testeg.svg"
import LabMM from "../images/icons/labmm.svg"
import DPB from "../images/icons/dpb.svg"
import MPP from "../images/icons/mpp.svg"
import AVMI from "../images/icons/avmi.svg"
import PDR from "../images/icons/pdr.svg"
import AV from "../images/icons/av.svg"
import Orto from "../images/icons/orto.svg"
import Outros from "../images/icons/outros.svg"

import Favicon from "../images/favicon.png"

const IndexPage = ({ data }) => {
  // console.log('data: ', data);
  // const allMembers = data.allWpMember.nodes
  // const heroImage = data.heroImage.edges[0].node.childImageSharp.fluid
  // const contacts = data.wpInfo.ScheduleAndContacts

  const [servicesHeight, setServicesHeight] = useState("auto")
  const [teamHeight, setTeamHeight] = useState("auto")
  const [contactsHeight, setContactsHeight] = useState("auto")

  const toggleServicesHeight = () =>
    setServicesHeight(servicesHeight === 0 ? "auto" : 0)
  const toggleTeamHeight = () => setTeamHeight(teamHeight === 0 ? "auto" : 0)
  const toggleContactsHeight = () =>
    setContactsHeight(contactsHeight === 0 ? "auto" : 0)

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <html lang="pt-PT" />

        <link href={Favicon} rel="shortcut icon" type="image/x-icon"></link>
        <title>Farmácia Lusitana de Coimbra</title>
        <meta name="description" content="Farmácia Lusitana de Coimbra" />
        <meta
          name="keywords"
          content="farmácia lusitana de coimbra, farmácia, coimbra, farmácia online, medicamentos online,farmácia,farmácias,medicamento, entrega ao domicílio, receita, cosmética,aconselhamento,bebé,beleza,compra de remedios online,comprar medicamentos,compras,entrega em casa,farmacêutico,geriatria,grávida"
        />

        <link rel="canonical" href="https://farmacialusitana-coimbra.pt/" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Farmácia Lusitana de Coimbra" />
        <meta
          property="og:description"
          content="Farmácia Lusitana de Coimbra"
        />
        <meta
          property="og:url"
          content="https://farmacialusitana-coimbra.pt/"
        />
        <meta property="og:site_name" content="Farmácia Lusitana de Coimbra" />
      </Helmet>

      <Navbar
        s={() => setServicesHeight("auto")}
        t={() => setTeamHeight("auto")}
        c={() => setContactsHeight("auto")}
      />
      {/* <OrderForm /> */}
      <main>
        <section className="hero">
          <div className="main-container">
            <div className="hero__flex">
              <div className="hero__flex__img">
                {/* <Img
                  style={{ height: "00px", width: "100%" }}
                  fluid={Template}
                  draggable={false}
                /> */}
                <div className="hide-mobile">
                  <StaticImage
                    src="../images/v1.4_FolhetoUcrania_Horizontal.jpg"
                    alt="Hero image"
                    quality={65}
                  />
                </div>

                <div className="hide-desktop">
                  <StaticImage
                    src="../images/v1.4_FolhetoUcrania.jpg"
                    alt="Hero image"
                    quality={65}
                  />
                </div>
              </div>
              {/* <div className="hero__flex__info">
                <div className="info__item">
                  <h1>Encomende os seus medicamentos aqui!</h1>
                  <span className="normal-text">
                    Entrega ao domicílio 24h por dia
                  </span>
                </div>

                <div className="info__item info__item--flex">
                  <div className="info__item__btn info__item__btn--right--border">
                    <ContactFormOpenButton text="Com receita" />
                    <span className="normal-text top20">
                      Use esta opção se pretender aviar uma receita médica.
                    </span>
                  </div>
                  <div className="info__item__btn">
                    <ContactFormOpenButton text="Sem receita" />
                    <span className="normal-text top20">
                      Para medicamentos não sujeitos a receita médica.
                    </span>
                  </div>
                </div>

                <div className="info__item">
                  <span className="normal-text highlight">Morada</span>
                  <span className="normal-text">
                    Rua da Fontinha nº1, 3045-069 Coimbra
                  </span>
                </div>

                <div className="info__item">
                  <span className="normal-text highlight">Telefone</span>
                  <span className="normal-text">+351 239 440 014</span>
                </div>
              </div> */}
            </div>
          </div>
        </section>
        <section className="content">
          <div className="main-container">
            <CollapseBox
              height={servicesHeight}
              toggle={toggleServicesHeight}
              goTo="servicos"
              headerContent={
                <>
                  <div className="collapse-box__header__title" id="servicos">
                    <img src={Arrow} alt="" />
                    <h1>Serviços</h1>
                  </div>
                  <div className="collapse-box__header__description">
                    <p className="normal-text">
                      Somos uma farmácia multidisciplinar com <br />
                      competências nas mais variadas áreas da saúde.
                    </p>
                  </div>
                </>
              }
              bodyContent={
                <div className="services-grid">
                  <div className="services-grid__item">
                    <img src={Dermo} alt="Dermocosmética" draggable="false" />
                    <span className="normal-text">Dermocosmética</span>
                  </div>
                  <div className="services-grid__item">
                    <img
                      src={TesteG}
                      alt="Teste de gravidez"
                      draggable="false"
                    />
                    <span className="normal-text">Teste de gravidez</span>
                  </div>
                  <div className="services-grid__item">
                    <img
                      src={LabMM}
                      alt="Laboratório de medicamentos manipulados"
                      draggable="false"
                    />
                    <span className="normal-text">
                      Laboratório de <br /> medicamentos <br /> manipulados
                    </span>
                  </div>
                  <div className="services-grid__item">
                    <img
                      src={DPB}
                      alt="Determinação de parâmetros bioquímicos"
                      draggable="false"
                    />
                    <span className="normal-text">
                      Determinação <br /> de parâmetros <br /> bioquímicos
                    </span>
                  </div>
                  <div className="services-grid__item">
                    <img
                      src={MPP}
                      alt="Medição da pressão arterial,peso, altura e IMC"
                      draggable="false"
                    />
                    <span className="normal-text">
                      Medição da <br /> pressão arterial, <br /> peso, altura e
                      IMC
                    </span>
                  </div>
                  <div className="services-grid__item">
                    <img
                      src={AVMI}
                      alt="Administração de vacinas e de medicamentos injectáveis"
                      draggable="false"
                    />
                    <span className="normal-text">
                      Administração <br /> de vacinas e de <br /> medicamentos{" "}
                      <br /> injectáveis
                    </span>
                  </div>
                  <div className="services-grid__item">
                    <img
                      src={PDR}
                      alt="Programas de diagnóstico e rastreio"
                      draggable="false"
                    />
                    <span className="normal-text">
                      Programas <br /> de diagnóstico <br /> e rastreio
                    </span>
                  </div>
                  <div className="services-grid__item">
                    <img
                      src={AV}
                      alt="Aconselhamento veterinário"
                      draggable="false"
                    />
                    <span className="normal-text">
                      Aconselhamento <br /> veterinário
                    </span>
                  </div>
                  <div className="services-grid__item">
                    <img src={Orto} alt="Ortopedia" draggable="false" />
                    <span className="normal-text">Ortopedia</span>
                  </div>
                  <div className="services-grid__item">
                    <img src={Outros} alt="Outros" draggable="false" />
                    <span className="normal-text">Outros</span>
                  </div>
                </div>
              }
            />

            <CollapseBox
              height={teamHeight}
              toggle={toggleTeamHeight}
              goTo="equipa"
              headerContent={
                <>
                  <div className="collapse-box__header__title" id="equipa">
                    <img src={Arrow} alt="" />
                    <h1>Equipa</h1>
                  </div>
                  <div className="collapse-box__header__description">
                    <p className="normal-text">
                      Possuímos os melhores profissionais no sentido
                      <br />
                      de fornecer o melhor serviço aos nossos clientes.
                    </p>
                  </div>
                </>
              }
              bodyContent={
                // <div className="team-grid">
                //   {allMembers.map(member => {
                //     const name = member.TeamMember.name
                //     const role = member.TeamMember.role
                //     const profileImage =
                //       member.TeamMember.profileimage.localFile.childImageSharp
                //         .fluid

                //     return (
                //       <div key={name} className="team-grid__item">
                //         <div className="team-grid__item__profile">
                //           <Img
                //             style={{ height: "100%", width: "100%" }}
                //             fluid={profileImage}
                //             draggable={false}
                //           />
                //         </div>
                //         <div className="team-grid__item__names">
                //           <div className="normal-text highlight">{role}</div>
                //           <div className="normal-text">{name}</div>
                //         </div>
                //       </div>
                //     )
                //   })}
                // </div>
                <div className="team-grid">
                  <div className="team-grid__item">
                    <div className="team-grid__item__names">
                      <div className="normal-text highlight">
                        Directora Tecnica
                      </div>
                      <div className="normal-text">Paula Murta</div>
                    </div>
                  </div>
                  <div className="team-grid__item">
                    <div className="team-grid__item__names">
                      <div className="normal-text highlight">Farmacêutico</div>
                      <div className="normal-text">Teresa Natario</div>
                    </div>
                  </div>
                  <div className="team-grid__item">
                    <div className="team-grid__item__names">
                      <div className="normal-text highlight">Farmacêutico</div>
                      <div className="normal-text">Paulo Marreiros</div>
                    </div>
                  </div>
                  <div className="team-grid__item">
                    <div className="team-grid__item__names">
                      <div className="normal-text highlight">Farmacêutico</div>
                      <div className="normal-text">Rita Pereira</div>
                    </div>
                  </div>
                  <div className="team-grid__item">
                    <div className="team-grid__item__names">
                      <div className="normal-text highlight">Farmacêutico</div>
                      <div className="normal-text">Joana Morais</div>
                    </div>
                  </div>
                  <div className="team-grid__item">
                    <div className="team-grid__item__names">
                      <div className="normal-text highlight">Farmacêutico</div>
                      <div className="normal-text">Paulo Cardoso</div>
                    </div>
                  </div>
                  <div className="team-grid__item">
                    <div className="team-grid__item__names">
                      <div className="normal-text highlight">Farmacêutico</div>
                      <div className="normal-text">Ines Oliveira</div>
                    </div>
                  </div>
                  <div className="team-grid__item">
                    <div className="team-grid__item__names">
                      <div className="normal-text highlight">Farmacêutico</div>
                      <div className="normal-text">Ana Laia</div>
                    </div>
                  </div>
                </div>
              }
            />

            <CollapseBox
              height={contactsHeight}
              toggle={toggleContactsHeight}
              goTo="horario-contactos"
              headerContent={
                <>
                  <div
                    className="collapse-box__header__title"
                    id="horario-contactos"
                  >
                    <img src={Arrow} alt="" />
                    <h1>Horário & Contactos</h1>
                  </div>
                  <div className="collapse-box__header__description">
                    <p className="normal-text">
                      Consulte aqui os nossos horários e localização.
                      <br />
                      Estamos sempre prontos para o ajudar.
                    </p>
                  </div>
                </>
              }
              bodyContent={
                // <div className="contacts-flex">
                //   <div className="contacts-flex__item">
                //     <span className="normal-text highlight">
                //       Segunda a sexta
                //     </span>
                //     <span className="normal-text">
                //       {contacts.segundaASexta}
                //     </span>
                //   </div>
                //   <div className="contacts-flex__item">
                //     <span className="normal-text highlight">
                //       Sábado e vésperas de feriado
                //     </span>
                //     <span className="normal-text">
                //       {contacts.sabadoEVesperasDeFeriado}
                //     </span>
                //   </div>
                //   <div className="contacts-flex__item">
                //     <span className="normal-text highlight">Domingo</span>
                //     <span className="normal-text">{contacts.domingo}</span>
                //   </div>
                //   <div className="contacts-flex__map">
                //     <Img
                //       fluid={
                //         contacts.imagemMapa.localFile.childImageSharp.fluid
                //       }
                //       draggable={false}
                //     />
                //     <a
                //       href="https://goo.gl/maps/w4K6XdwQ4kM6PEt3A"
                //       target="_blank"
                //       rel="noopener noreferrer"
                //       className="highlight"
                //     >
                //       Ver no mapa <strong>↗</strong>
                //     </a>
                //   </div>
                //   <div className="contacts-flex__item">
                //     <span className="normal-text highlight">Morada</span>
                //     <span className="normal-text">{contacts.morada}</span>
                //   </div>

                //   <div className="contacts-flex__item">
                //     <span className="normal-text highlight">Telefone</span>
                //     <span className="normal-text">{contacts.telefone}</span>
                //   </div>
                //   <div className="contacts-flex__item">
                //     <span className="normal-text highlight">Redes Sociais</span>
                //     <span className="normal-text">
                //       {parse(contacts.redesSociais)}
                //     </span>
                //   </div>
                //   <div className="contacts-flex__alert">
                //     <span className="normal-text">
                //       A submissão de pedidos de entrega ao domicílio pode ser
                //       efetuada 24h por dia, todos os dias.
                //     </span>
                //   </div>
                // </div>
                <div className="contacts-flex">
                  <div className="contacts-flex__alert" id="encomendar">
                    <span className="normal-text">
                      Pode efetuar as suas encomendas de medicamentos através
                      dos seguintes contactos: <br />
                      <a href="mailto:farmacialusitana@gmail.com">
                        farmacialusitana@gmail.com
                      </a>{" "}
                      / 239 440 014 / 910 256 723 (SMS ou Whatsapp)
                    </span>
                  </div>
                  <div className="contacts-flex__item">
                    <span className="normal-text highlight">
                      Segunda a sexta
                    </span>
                    <span className="normal-text">9h00–20h00</span>
                  </div>
                  <div className="contacts-flex__item">
                    <span className="normal-text highlight">
                      Sábado e vésperas de feriado
                    </span>
                    <span className="normal-text">9h00–13h00</span>
                  </div>
                  <div className="contacts-flex__item">
                    <span className="normal-text highlight">Domingo</span>
                    <span className="normal-text">Fechado</span>
                  </div>
                  <div className="contacts-flex__map">
                    {/* <Img
                      style={{ height: "100%", width: "100%" }}
                      fluid={Mapa}
                      draggable={false}
                    /> */}
                    <StaticImage
                      src="../images/fl_map-p-2600.jpg"
                      alt="Map image"
                      quality={100}
                    />
                    <a
                      href="https://goo.gl/maps/w4K6XdwQ4kM6PEt3A"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="highlight"
                    >
                      Ver no mapa <strong>↗</strong>
                    </a>
                  </div>
                  <div className="contacts-flex__item">
                    <span className="normal-text highlight">Morada</span>
                    <span className="normal-text">
                      Rua da Fontinha nº1, 3045-069 Coimbra
                    </span>
                  </div>

                  <div className="contacts-flex__item">
                    <span className="normal-text highlight">Telefone</span>
                    <span className="normal-text">+351 239 440 014</span>
                  </div>
                  <div
                    className="contacts-flex__item"
                    style={{ borderBottom: "0px" }}
                  >
                    <span className="normal-text highlight">Redes Sociais</span>
                    <span className="normal-text">
                      <a
                        href="https://www.facebook.com/farmacia.lusitana"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Facebook
                      </a>
                    </span>
                  </div>
                </div>
              }
            />
          </div>
        </section>
      </main>

      <Footer
      // segunda={contacts.segundaASexta}
      // sabado={contacts.sabadoEVesperasDeFeriado}
      // domingo={contacts.domingo}
      // morada={contacts.morada}
      // telefone={contacts.telefone}
      // redes={contacts.redesSociais}
      />

      <div className="alert-marquee">
        <Marquee gradient={false} speed={50}>
          <div className="alert-marquee__text highlight">
            {" "}
            NORMAS COVID-19: A nossa farmácia encontra-se aberta com serviço
            presencial. Tomámos todas as percauções necessárias para que nos
            visite em segurança.
          </div>
          <div className="alert-marquee__separator">✚</div>
          <div className="alert-marquee__text highlight">
            {" "}
            NORMAS COVID-19: A nossa farmácia encontra-se aberta com serviço
            presencial. Tomámos todas as percauções necessárias para que nos
            visite em segurança.
          </div>
          <div className="alert-marquee__separator">✚</div>
        </Marquee>
      </div>
    </React.Fragment>
  )
}

// export const pageQuery = graphql`
//   query WPMembers {
//     allWpMember(sort: { fields: [date], order: ASC }) {
//       nodes {
//         date(formatString: "MMMM DD, YYYY")
//         TeamMember {
//           name
//           role
//           profileimage {
//             localFile {
//               childImageSharp {
//                 fluid(maxWidth: 1000, quality: 50) {
//                   ...GatsbyImageSharpFluid_withWebp
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//     heroImage: allFile(filter: { name: { eq: "hero_img" } }) {
//       edges {
//         node {
//           childImageSharp {
//             fluid(quality: 100) {
//               ...GatsbyImageSharpFluid_withWebp
//             }
//           }
//         }
//       }
//     }
//     wpInfo {
//       ScheduleAndContacts {
//         domingo
//         fieldGroupName
//         morada
//         redesSociais
//         sabadoEVesperasDeFeriado
//         segundaASexta
//         telefone
//         imagemMapa {
//           localFile {
//             childImageSharp {
//               fluid(quality: 100) {
//                 ...GatsbyImageSharpFluid_withWebp
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `

export default IndexPage
