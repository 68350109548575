import React from "react"
import { animateScroll as scroll } from "react-scroll"
import parse from "html-react-parser"
import { StaticImage } from "gatsby-plugin-image"
import FooterLogo from "../../images/farmacia_lusitana_white_full.svg"

export default function Footer(props) {
  const goHome = () => {
    scroll.scrollToTop()
  }

  return (
    <header className="footer">
      <div className="main-container">
        <div className="footer__grid">
          <div className="footer__grid__logo">
            <button className="logo-button" onClick={goHome}>
              <img src={FooterLogo} alt="logo" draggable="false" />
            </button>
          </div>
          <nav className="footer__grid__nav">
            <div className="footer__grid__nav__item">
              <p className="small-text highlight">Hórario</p>
              <p className="small-text">Segunda a sexta</p>
              <p className="small-text">9H00 – 20H00</p>
              <p className="small-text top5">Sábado</p>
              <p className="small-text">9h00 – 13H00</p>
              <p className="small-text top5">Domingo</p>
              <p className="small-text">Fechado</p>
            </div>
            <div></div>
            <div className="footer__grid__nav__item">
              <p className="small-text highlight">Morada</p>
              <p className="small-text">
                Rua da Fontinha nº1, <br />
                3045-069 Coimbra
              </p>
              <p className="small-text highlight top5">Telefone</p>
              <p className="small-text">+351 239 440 014</p>
              <p className="small-text highlight top5">Redes Sociais</p>
              <p className="small-text">
                <a
                  href="https://www.facebook.com/farmacia.lusitana"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook
                </a>
              </p>
            </div>
            <div></div>
            <div className="footer__grid__nav__item">
              <p className="small-text highlight">Termos e condições</p>
              <p className="small-text highlight top5">
                Política de Privacidade
              </p>
              <p className="small-text highlight top5">Política de Segurança</p>
              <p className="small-text highlight top20">
                © 2021 Farmácia Lusitana
              </p>
            </div>
          </nav>
        </div>
        <div className="footer__logos">
          <a href="/Doc1_Cartaz_PM.pdf" download>
            <StaticImage src="../../images/barra_logos.png" alt="Logos" />
          </a>
        </div>
      </div>
    </header>
  )
}
