import React from "react"
import AnimateHeight from "react-animate-height"
import { Link } from "react-scroll"

export default function CollapseBox(props) {
  return (
    <div className="collapse-box">
      <Link to={props.goTo} smooth={true} duration={500} offset={-120}>
        <div
          className={
            props.height !== 0
              ? "collapse-box__header collapse-box__header--opened"
              : "collapse-box__header"
          }
          onClick={props.toggle}
          onKeyDown={props.toggle}
          role="button"
          tabIndex={0}
        >
          {props.headerContent}
        </div>
      </Link>

      <AnimateHeight
        className="collapse-box__body"
        duration={400}
        height={props.height}
      >
        {props.bodyContent}
      </AnimateHeight>
    </div>
  )
}
